import { Component, OnDestroy, OnInit } from '@angular/core';
import { IrisMsalService } from '@iris/modules/msal/msal.service';
import { Subject, take, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { withLatestFrom } from 'rxjs/operators';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';

const LAST_HREF_KEY = 'lastHref';

@Component({
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoLoginComponent implements OnInit, OnDestroy {
  private readonly destroySubject = new Subject<void>();

  storageLastHref: string = this.localStorage.getItem(LAST_HREF_KEY);

  constructor(
    private readonly router: Router,
    private readonly irisMsalService: IrisMsalService,
    private readonly authFacade: AuthFacade,
    private readonly route: ActivatedRoute,
    private readonly localStorage: IrisLocalStorageService,
  ) {}

  ngOnInit(): void {
    this.checkAccountAndRedirect();
  }

  checkAccountAndRedirect(): void {
    const account = this.irisMsalService.checkAndSetActiveAccount();
    if (!account) {
      this.handleAzureAccount();
      return;
    }
    this.irisMsalService.acquireTokenSilent(true).pipe(
      take(1),
      withLatestFrom(this.route.queryParams),
      tap(([success, params]) => {
        if (!success) {
          void this.router.navigate(['login'], { queryParams: { ssoError: true } });
          return;
        }
        this.localStorage.removeItem(LAST_HREF_KEY);
        // backUrl needs for pwa SSO login
        const lastHref = params['backUrl'] as string || this.authFacade.lastHref || this.storageLastHref;
        if (lastHref) {
          location.assign(this.authFacade.getSafeUrl(lastHref));
          return;
        }
        location.assign('/');
      }),
    ).subscribe();
  }

  handleAzureAccount(): void {
    this.irisMsalService.handleAzureAccount().pipe(
      take(1),
      tap(res => {
        if (res) {
          this.checkAccountAndRedirect();
        }
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
