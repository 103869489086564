import { computed, Injectable, signal } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { catchError, concatMap, finalize, map, Observable, of, switchMap, take } from 'rxjs';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { getAzureScopes } from './msal.config';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class IrisMsalService {
  private readonly activeRequest$ = signal<Observable<boolean>>(null);
  private readonly azureAuthResult = signal<AuthenticationResult>(null);
  readonly azureAccessToken = computed(() => this.azureAuthResult()?.accessToken);
  readonly azureTokenExpiresOn = computed(() => this.azureAuthResult()?.expiresOn);
  readonly azureAccessToken$ = toObservable(this.azureAccessToken).pipe(
    switchMap(accessToken => {
      if (this.azureTokenExpired || !accessToken) {
        return this.acquireTokenSilent().pipe(
          map(() => this.azureAccessToken()),
        );
      }
      return of(accessToken);
    }),
  );

  private get azureTokenExpired(): boolean {
    return this.azureTokenExpiresOn() <= new Date();
  }

  constructor(
    private readonly msalService: MsalService,
    private readonly authFacade: AuthFacade,
    private readonly authService: IrisAuthService,
  ) {
    msalService.initialize();
  }

  handleAzureAccount(): Observable<boolean> {
    return this.msalService.initialize().pipe(
      concatMap(() => this.msalService.handleRedirectObservable()),
      take(1),
      map(() => !!this.checkAndSetActiveAccount()),
      switchMap((res) => {
        if (!res) {
          this.msalService.loginRedirect();
        }
        return of(res);
      }),
      catchError((err) => {
        console.error('msalService.handleRedirectObservable():', err);
        return of(false);
      }),
    );
  }

  checkAndSetActiveAccount(): AccountInfo {
    if (!this.msalService.instance.getActiveAccount() && this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
    }

    return this.msalService.instance.getActiveAccount();
  }

  acquireTokenRedirect(): Observable<boolean> {
    return this.msalService.initialize().pipe(
      concatMap(() => this.msalService.acquireTokenRedirect({
        scopes: getAzureScopes(this.authService),
        redirectUri: this.authService.getMsalRedirectUri(),
      })),
      map(() => true),
      catchError((err) => {
        console.error('msalService.acquireTokenRedirect():', err);
        return of(false);
      }),
    );
  }

  acquireTokenSilent(initialLogin = false): Observable<boolean> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (this.activeRequest$()) {
      return this.activeRequest$();
    }

    const request = this.msalService.initialize().pipe(
      concatMap(() => this.msalService.acquireTokenSilent({
        scopes: getAzureScopes(this.authService, initialLogin),
        redirectUri: this.authService.getMsalRedirectUri(),
        account: activeAccount,
      })),
      switchMap((res: AuthenticationResult) => {
        this.azureAuthResult.set(res);
        return this.authFacade.loginAzure(res.accessToken).pipe(
          map(() => true),
        );
      }),
      catchError((err) => {
        console.error('msalService.acquireTokenSilent():', err);
        return err instanceof InteractionRequiredAuthError
          ? this.acquireTokenRedirect()
          : of(false);
      }),
      finalize(() => this.activeRequest$.set(null)),
    );
    this.activeRequest$.set(request);
    return request;
  }

  removeActiveAccount(): void {
    const activeAccount = this.checkAndSetActiveAccount();
    if (activeAccount) {
      this.msalService.instance.setActiveAccount(null);
    }
  }
}
