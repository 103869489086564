import { inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageResolver } from '@ngx-strakit/validation';

@Injectable()
export class SkRequiredValidationMessageResolver implements ValidationMessageResolver {
  private readonly translate = inject(TranslateService);
  readonly errorCode = Validators.required.name;

  getMessage(): string {
    return this.translate.instant('label.validation.required');
  }
}
