import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, finalize, map, tap } from 'rxjs/operators';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { emptyIrisPage, IrisPage, unboundedIrisPage } from '@iris/common/models/page';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { USER_SELECT_OPTIONS } from '@iris/common/modules/fields/field-user-select/tokens';
import { IRIS_CURRENT_USER_ID } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { TranslateService } from '@ngx-translate/core';
import { IrisSelectEngineInfinityScroll } from '@iris/common/modules/fields/ng-select-field/select-engine.infinity-scroll';
import { SearchContext } from '@iris/common/modules/fields/ng-select-field/search-processing';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { queryParamsToBodyPayload } from '@iris/common/utils/iris-query-params.utils';

export class FieldOptions {
  showAvatar = true;
  showAvatarForSelected = true;
  showCompany = false;
  showCompanyForSelected = false;
  showEmail = true;
  showEmailForSelected = false;
  showDepartment = false;
  typeToSearchText = 'label.StartTypingNameOrSurname';
  placeholder: string;
  sortByFullName = true;
  searchable = true;
  multiple = false;
  panelWidth = null;
}

export interface SearchOptions {
  limit: number;
  sortByName: boolean;
  excludedItems?: number[];
  searchByApiUser?: boolean;
  searchBySSOUser?: boolean;
}

export type UserSearchContext<TItem = IrisUserInfoI> = SearchContext<TItem> & { searchOptions?: SearchOptions };

@Injectable()
export class IrisUserSelect extends IrisSelectEngineInfinityScroll<IrisUserInfoI, unknown, UserSearchContext> {
  readonly options: FieldOptions = new FieldOptions();

  private readonly limitSubject = new BehaviorSubject<number | null>(10);
  private readonly sortByNameSubject = new BehaviorSubject<boolean>(true);
  private readonly excludedItemsSubject = new BehaviorSubject<number[] | null>([]);
  private readonly searchByApiUserSubject = new BehaviorSubject<boolean>(false);
  private readonly searchBySSOUserSubject = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(USER_SELECT_OPTIONS) @Optional() options: Partial<FieldOptions>,
    protected readonly userService: IrisUserService,
    protected readonly translateService: TranslateService,
    protected readonly globalSandbox: IrisGlobalSandbox,
  ) {
    super();
    if (options) {
      this.options = Object.assign(this.options, options);
    }
  }

  setLimit(limit: number): void {
    this.limitSubject.next(limit);
  }

  setSortByName(value: boolean): void {
    this.options.sortByFullName = value;
    this.sortByNameSubject.next(value);
  }

  setExcludedItems(items: number[] | null): void {
    this.excludedItemsSubject.next(items);
  }

  setSearchByApiUser(value: boolean): void {
    this.searchByApiUserSubject.next(value);
  }

  setSearchBySSOUser(value: boolean): void {
    this.searchBySSOUserSubject.next(value);
  }

  protected handleSearchOptions$(ctx: UserSearchContext): Observable<UserSearchContext> {
    return combineLatest([
      this.limitSubject,
      this.sortByNameSubject,
      this.excludedItemsSubject,
      this.searchByApiUserSubject,
      this.searchBySSOUserSubject,
    ]).pipe(
      debounceTime(0),
      map(([limit, sortByName, excludedItems, searchByApiUser, searchBySSOUser]) => ({
        ...ctx,
        searchOptions: {
          limit,
          sortByName,
          excludedItems,
          searchByApiUser,
          searchBySSOUser,
        },
      })),
    );
  }

  searchItems$(term: string, offset: number, ctx: UserSearchContext): Observable<IrisPage<IrisUserInfoI>> {
    const queryParams = this.buildQueryParams(term, ctx.searchOptions, offset);
    if (!queryParams) { return of(emptyIrisPage<IrisUserInfoI>()); }

    this.loadingSubject.next(true);
    const body = queryParamsToBodyPayload(queryParams);
    return this.userService.elasticPostQuery(term, body).pipe(
      catchError(() => of([])),
      tap(elements => this.globalSandbox.setLoadedUsers(elements)),
      map(elements => unboundedIrisPage(elements)),
      finalize(() => this.loadingSubject.next(false)),
    );
  }

  protected buildQueryParams(term: string, options: SearchOptions, offset = 0): IrisQueryParams {
    const params = new IrisQueryParamsBuilder().filter('enabled', [true]);

    if (term) {
      params.urlParam('value', term);
    }

    if (options?.sortByName) {
      params.orderBy('fullName', 'asc');
    }

    if (options?.limit) {
      params.limit(options?.limit);
    }

    if (offset != null) {
      params.offset(offset);
    }

    if (options?.excludedItems?.length) {
      const excludedItems = options?.excludedItems.map(id => id === IRIS_CURRENT_USER_ID ? this.userService.me.id : id);
      params.filter('id', excludedItems, t => t.not(true));
    }

    if (options?.searchByApiUser) {
      params.filter('accountType', ['API_USER']);
    }

    if (options?.searchBySSOUser) {
      params.filter('ssoActive', [true]);
    }

    return params.toStructure();
  }

  getMissingItemLabelFn(val: number | Record<string, unknown>): Observable<string> {
    if (val == null) { return null; }
    const userId = typeof val === 'object' ? Number(val['id']) : val;

    if (userId === IRIS_CURRENT_USER_ID) {
      return of(this.translateService.instant('label.CurrentUser'));
    }

    const params = new IrisQueryParamsBuilder()
      .onlyFields(['fullName'])
      .toStructure();

    return this.userService.getById(userId, params).pipe(
      map(user => user?.fullName),
      catchError(() => of(null)),
    );
  }
}
