import { IrisQueryParams } from '@iris/api-query';

export function queryParamsToBodyPayload(queryParams: IrisQueryParams = new IrisQueryParams()): Record<string, unknown> {
  const payload: Record<string, unknown> = {};

  if (queryParams.filter?.length) {
    payload['filter'] = queryParams.filter.map(t => t.toObject());
  }
  if (queryParams.orderBy?.length) {
    payload['orderBy'] = queryParams.orderBy.map(t => t.toObject());
  }
  if (queryParams.limit) {
    payload['limit'] = queryParams.limit;
  }
  if (queryParams.offset || queryParams.offset === 0) {
    payload['offset'] = queryParams.offset;
  }
  if (queryParams.onlyFields?.length) {
    payload['onlyFields'] = queryParams.onlyFields;
  }
  if (queryParams.excludeFields?.length) {
    payload['excludeFields'] = queryParams.excludeFields;
  }
  if (queryParams.urlParams?.length) {
    queryParams.urlParams.forEach(p => {
      payload[p.name] = typeof p.value === 'object' ? JSON.stringify(p.value) : p.value;
    });
  }

  return payload;
}
