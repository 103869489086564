import { Injectable, SecurityContext } from '@angular/core';
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, share, switchMap, take, tap } from 'rxjs/operators';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { TokenStorage } from '@iris/modules/auth/utils/token-storage';
import { LoginResult } from '@iris/modules/auth/utils/login-result';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { SWService } from '@iris/common/services/service-worker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService } from '@iris/common/modules/rum/logger.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  private readonly tokenStorage = new TokenStorage(this.environmentService.env.instanceId);
  readonly accessTokenExpiredAt = this.tokenStorage.accessTokenExpiredAt;

  lastHref: string;

  private activeRefresh$: Observable<string>;

  constructor(
    private readonly authService: IrisAuthService,
    private readonly environmentService: IrisEnvironmentService,
    private readonly swService: SWService,
    private readonly sanitizer: DomSanitizer,
    private readonly loggerService: LoggerService,
  ) {
    this.swService.authSWInit$.pipe(
      tap(() => {
        const token = this.tokenStorage.accessToken;
        if (token) { this.swService.updateToken(token); }
      }),
      takeUntilDestroyed(),
    ).subscribe();
  }

  actualToken(forceRefresh = false): Observable<string> {
    if (!forceRefresh && this.tokenStorage.accessTokenIsNotExpired() && this.isWellFormattedToken(this.tokenStorage.accessToken)) {
      return of(this.tokenStorage.accessToken);
    }

    this.activeRefresh$ ??= this.tokenStorage.refreshToken ? this.requestTokenRefresh() : of(this.tokenStorage.accessToken);

    return this.activeRefresh$;
  }

  isLoggedIn(): boolean {
    return !!this.tokenStorage.accessToken;
  }

  private processLoginResult(loginResult: LoginResult): Observable<LoginResult> {
    this.tokenStorage.setLoginResult(loginResult);
    return this.shareToken(loginResult?.accessToken).pipe(
      map(() => loginResult),
    );
  }

  private requestTokenRefresh(): Observable<string> {
    if (!this.tokenStorage.refreshToken) {
      this.logout();
      return of(null);
    }

    return this.authService.refreshToken(this.tokenStorage.refreshToken).pipe(
      switchMap((loginResult) => {
        if ( !this.isWellFormattedToken(loginResult?.accessToken)) {
          return throwError(() => new Error('Invalid token'));
        }
        return this.processLoginResult(loginResult);
      }),
      catchError((error: HttpErrorResponse) => {
        const message = `Message: User token refresh | Error Status: ${error.status} | RefreshToken: ${this.tokenStorage.refreshToken}`;
        void this.loggerService.logMessageToKibana(message, 'Authorization error').then(() => {
          this.logout();
        });
        return of(null);
      }),
      map((loginResult) => loginResult?.accessToken),
      finalize(() => this.activeRefresh$ = null),
      share({ resetOnRefCountZero: false }),
    );
  }

  loginUsername(username: string, password: string): Observable<LoginResult> {
    return this.authService.loginUsername(username, password).pipe(
      switchMap((loginResult) => this.processLoginResult(loginResult)),
      catchError(() => {
        this.tokenStorage.clear();
        return throwError(() => new Error('error.UserAuthFailed'));
      }),
    );
  }

  impersonateUserById(userId: number): Observable<LoginResult> {
    return this.authService.impersonateUserById(userId).pipe(
      switchMap((loginResult) => this.processLoginResult(loginResult)),
      catchError(() => {
        return throwError(() => new Error('error.UserAuthFailed'));
      }),
    );
  }

  loginAzure(azureAccessToken: string): Observable<LoginResult> {
    return this.authService.loginAzure(azureAccessToken).pipe(
      switchMap((loginResult) => this.processLoginResult(loginResult)),
      catchError(() => {
        this.tokenStorage.clear();
        return throwError(() => new Error('error.UserAuthFailed'));
      }),
    );
  }

  shareToken(accessToken: string): Observable<string> {
    if (accessToken) {
      this.swService.updateToken(accessToken);
      return this.authService.updateNodeToken(accessToken).pipe(
        map(() => accessToken));
    }
    return of(accessToken);
  }

  logout(isBackLogout = false): void {
    this.authService.updateNodeToken().pipe(
      switchMap(() => isBackLogout && this.isLoggedIn() ? this.authService.logout() : of(null)),
      take(1),
      tap(() => {
        this.tokenStorage.clear();
        location.reload();
      }),
    ).subscribe();
  }

  getTokensData(): { accessToken: string; refreshToken: string } {
    return {
      accessToken: this.tokenStorage.accessToken,
      refreshToken: this.tokenStorage.refreshToken,
    };
  }

  getSafeUrl(url: string): string {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url) || '';
    return sanitizedUrl.startsWith('unsafe') ? undefined : sanitizedUrl;
  }

  isWellFormattedToken(token: string): boolean {
    return !!token && token.split('.').length === 3;
  }
}
